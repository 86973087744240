/*
  Site nav Algolia autocomplete search
  @see https://www.algolia.com/doc/ui-libraries/autocomplete/introduction/what-is-autocomplete/

  Some pages could have multiple instances of this class (e.g. home page)

  Instance variables:
    - `this.autocomplete`: the Algolia autocomplete instance
    - `this.container`: the container element for the autocomplete
    - `this.panelContainer`: the container element for the autocomplete panel
    - `this.placeholderInput`: the temporary input element that is shown while the autocomplete is loading
    - `this.searchTypeSelect`: the select dropdown for choosing the search type (shots, designers, services)
    - `this.searchClient`: the Algolia search client

  Static methods:
    These can be called from the base class like `SiteNavAutocomplete.methodName()`

    - `setQuery`: set the query for all autocomplete instances on the page
    - `registerSearchCallback`: register a callback to be executed when an autocomplete
      performs a search (e.g. designer/service search). Allows cancelling the default search behavior
    - `getSiteNavAutocompleteInstances`: get all `SiteNavAutocomplete` instances on the page

*/

import { autocomplete } from '@algolia/autocomplete-js';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import { uniqBy, limit } from '~/shared/utils/algolia-autocomplete-helpers';
import isFeatureGateEnabled from '~/shared/utils/feature-gates';

// Setup helper functions to remove duplicates and limit the number of items
// @see https://www.algolia.com/doc/ui-libraries/autocomplete/guides/reshaping-sources/
const removeDuplicates = uniqBy(({ source, item }) => (source.sourceId === 'querySuggestions' ? item.query : item.label));
const limitSuggestions = limit(6);

// The keys for the possible selectable search types.
// We use these to determine which Algolia index to use for query suggestions.
const querySuggestionIndexKeys = ['shots', 'designers', 'services'];

export class SiteNavAutocomplete {
  constructor(container) {
    this.autocomplete = null;
    this.container = container;
    this.panelContainer = container?.closest('[data-nav-autocomplete-panel-container]');
    this.placeholderInput = container?.querySelector('input');
    this.searchTypeSelect = this.panelContainer?.querySelector('[data-site-nav-search-type]');
    this.submitButton = this.panelContainer?.querySelector('[data-site-nav-search-submit]');
    this.query = this.placeholderInput?.value || '';

    if (!this.placeholderInput || !this.panelContainer || !this.searchTypeSelect) {
      console.error('Site search input, autocomplete containers, or search type select not found');
      return;
    }

    // setup algolia search client
    const applicationId = this.container.getAttribute('data-algolia-application-id');
    const searchApiKey = this.container.getAttribute('data-algolia-search-api-key');

    if (!applicationId || !searchApiKey) {
      console.error('Algolia application ID or search API key not found');
      return;
    }

    this.searchClient = algoliasearch(applicationId, searchApiKey);

    // initialize the autocomplete instance
    container.siteNavAutoComplete = this;
    this.init();
  }

  init() {
    const getQuerySuggestionsPlugin = (indexName) => createQuerySuggestionsPlugin({
      searchClient: this.searchClient,
      indexName,

      getSearchParams() {
        return {
          hitsPerPage: 6,
        };
      },

      transformSource: ({ source }) => ({
        ...source,
        sourceId: 'querySuggestions',
        getItemUrl: ({ item }) => this.getUrlFromQuery(item.query),

        onSelect: ({ item, event }) => {
          this.handleSearch(item.query, event);
        },

        templates: {
          item: (params) => {
            const { item, html } = params;

            return html`<a class="aa-ItemLink" href="${this.getUrlFromQuery(item.query)}">
                ${source.templates.item(params).props.children}
              </a>`;
          },
        },
      }),
    });

    // Create a recent searches plugin with local storage
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'site-nav',
      limit: 2,

      transformSource: ({ source }) => ({
        ...source,
        sourceId: 'recentSearches',
        getItemUrl: ({ item }) => this.getUrlFromQuery(item.label),

        onSelect: ({ item, event }) => {
          this.handleSearch(item.label, event);
        },

        templates: {
          item: (params) => {
            const { item, html } = params;

            return html`<a class="aa-ItemLink" href="${this.getUrlFromQuery(item.label)}">
              ${source.templates.item(params).props.children}
            </a>`;
          },
        },
      }),
    });

    // Create a query suggestions plugin for each search type
    const querySuggestionPlugins = querySuggestionIndexKeys.reduce((acc, key) => {
      const indexName = this.container.getAttribute(`data-algolia-${key}-index-name`);

      if (!indexName) {
        console.warn(`Algolia ${key} index name not found`);
        return acc;
      }

      acc[key] = getQuerySuggestionsPlugin(indexName);
      return acc;
    }, {});

    // Initialize the autocomplete instance
    this.autocomplete = autocomplete({
      container: this.container,
      panelContainer: this.panelContainer,
      openOnFocus: true,
      insights: true,
      placeholder: this.placeholderInput.placeholder,
      detachedMediaQuery: isFeatureGateEnabled('siteNavAutocomplete') ? '(max-width: 680px)' : 'none',
      plugins: isFeatureGateEnabled('siteNavAutocomplete') ? [recentSearchesPlugin] : [],

      classNames: {
        input: this.placeholderInput.className,
        detachedSearchButton: this.placeholderInput.className,
      },

      initialState: {
        query: this.placeholderInput.value,
      },

      onStateChange: ({ state, prevState }) => {
        // synchronize the query across multiple autocomplete instances (e.g. homepage)
        this.constructor.getSiteNavAutocompleteInstances().forEach((siteNavAutoComplete) => {
          // skip if this instance is the one that triggered the change
          // or if the query hasn't changed
          if (siteNavAutoComplete === this || state.query === prevState.query) return;
          siteNavAutoComplete.autocomplete?.setQuery(state.query);
        });

        // sync query
        this.query = state.query;
      },

      onReset: () => {
        this.container.dispatchEvent(new CustomEvent('site-nav-autocomplete:reset', {
          bubbles: true,
        }));
      },

      onSubmit: ({ state, event }) => {
        this.handleSearch(state.query, event);
      },

      getSources: (props) => {
        if (!isFeatureGateEnabled('siteNavAutocomplete')) return [];

        const searchType = this.getSelectedSearchType();

        return querySuggestionPlugins[searchType]?.getSources(props) || [];
      },

      // reshape data to remove duplicates and limit the total number of items
      // @see https://www.algolia.com/doc/ui-libraries/autocomplete/guides/reshaping-sources/
      reshape({ sourcesBySourceId }) {
        const {
          recentSearches, // custom source id we set in the recent searches plugin
          querySuggestions, // custom source id we set in the query suggestions plugin
          ...rest
        } = sourcesBySourceId;

        return [
          limitSuggestions(
            removeDuplicates(recentSearches, querySuggestions),
          ),

          Object.values(rest),
        ];
      },
    });

    // When having multiple instances of autocomplete on the same page, they can
    // get stuck open.
    // @see https://support.algolia.com/hc/en-us/articles/14424771026833-Can-I-have-two-AutoComplete-SearchBoxes-on-the-same-page
    //
    // We can manually close the autocomplete when clicking outside of it.
    document.addEventListener('click', (event) => {
      const clickedOutsideAutocomplete = !this.panelContainer.contains(event.target);
      const clickedInsideDetachedPanel = !!event.target.closest('.aa-DetachedOverlay');

      if (clickedOutsideAutocomplete && !clickedInsideDetachedPanel) {
        this.autocomplete?.setIsOpen(false);
      }
    });

    // Clear results when changing search type
    this.searchTypeSelect.addEventListener('change', () => {
      this.autocomplete?.setCollections([]);
    });

    // Perform search when clicking the search button
    this.submitButton?.addEventListener('click', (e) => {
      this.handleSearch(this.query, e);
    });

    // If placeholder input is focused on mount, focus the real autocomplete when
    // it gets injected for optimal UX
    if (document.activeElement === this.placeholderInput) {
      setTimeout(() => {
          this.container.querySelector('input')?.focus();
          this.autocomplete?.setIsOpen(true);
      }, 0);
    }

    // Remove placeholder input once real autocomplete is injected
    this.placeholderInput.remove();
  }

  // Determine target url based on the selected search type and query
  getUrlFromQuery(query) {
    const searchType = this.getSelectedSearchType();
    let url;

    if (searchType === 'shots') {
      url = `/search?q=${query}`;
    } else if (searchType === 'designers') {
      url = `/designers?search[keywords]=${query}`;
    } else if (searchType === 'services') {
      url = `/services?search[keywords]=${query}`;
    }

    return url;
  }

  getSelectedSearchType() {
    return this.searchTypeSelect.value || 'shots';
  }

  handleSearch(query, event) {
    event.stopPropagation();
    event.preventDefault();

    // Update/close the autocomplete
    this.autocomplete?.setQuery(query);
    this.autocomplete?.setIsOpen(false);
    this.container.querySelector('input')?.blur();

    // Close mobile search if open
    document.body.removeAttribute('data-site-nav-search-open');

    // Execute all consumer callbacks (e.g. designer/service search when searching from nav)
    for (const callback of this.constructor.getSearchCallbacks()) {
      if (callback(query, this.getSelectedSearchType()) === false) {
        // cancel redirect if any callback returns false
        return;
      }
    }

    // Otherwise redirect to desired search page
    window.location.href = this.getUrlFromQuery(query);
  }

  // Consumers can register a callback to be executed when the autocomplete performs
  // a search (via submit or selecting an item). By returning false from the callback,
  // the default behavior (redirecting to a search page) can be cancelled.
  //
  // Example usage:
  // SiteNavAutocomplete.registerSearchCallback((query, searchType) => { ... });
  static registerSearchCallback(callback) {
    this.getSearchCallbacks().push(callback);
  }

  static getSearchCallbacks() {
    if (!window.siteNavSearchCallbacks) window.siteNavSearchCallbacks = [];
    return window.siteNavSearchCallbacks;
  }

  static getSiteNavAutocompleteInstances() {
    return Array.from(document.querySelectorAll('[data-nav-autocomplete-container]'))
      .filter((container) => !!container.siteNavAutoComplete)
      .map((container) => container.siteNavAutoComplete);
  }

  // Allow consumers to set the query for all autocomplete instances on the page.
  //
  // Example usage:
  // SiteNavAutocomplete.setQuery('my query');
  static setQuery(query) {
    this.getSiteNavAutocompleteInstances().forEach((siteNavAutoComplete) => {
      siteNavAutoComplete.autocomplete?.setQuery(query);
    });
  }
}
