// Reshape function to remove duplicates from multiple algolia autocomplete sources
// @see https://www.algolia.com/doc/ui-libraries/autocomplete/guides/reshaping-sources/#create-a-reshape-function
export const uniqBy = (predicate) => function runUniqBy(...rawSources) {
  const sources = rawSources.flat().filter(Boolean);
  const seen = new Set();

  return sources.map((source) => {
    const items = source.getItems().filter((item) => {
      const appliedItem = predicate({ source, item });
      const hasSeen = seen.has(appliedItem);

      seen.add(appliedItem);

      return !hasSeen;
    });

    return {
      ...source,
      getItems() {
        return items;
      },
    };
  });
};

// Reshape function to limit the total number of items returned from multiple sources
// @see https://www.algolia.com/doc/ui-libraries/autocomplete/guides/reshaping-sources/#combine-reshape-functions
export const limit = (value) => function runLimit(...rawSources) {
  const sources = rawSources.flat().filter(Boolean);
  const limitPerSource = Math.ceil(value / sources.length);
  let sharedLimitRemaining = value;

  return sources.map((source, index) => {
    const isLastSource = index === sources.length - 1;
    const sourceLimit = isLastSource
      ? sharedLimitRemaining
      : Math.min(limitPerSource, sharedLimitRemaining);

    const items = source.getItems().slice(0, sourceLimit);
    sharedLimitRemaining = Math.max(sharedLimitRemaining - items.length, 0);

    return {
      ...source,
      getItems() {
        return items;
      },
    };
  });
};
